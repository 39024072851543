(function($) {
    $(function() {
        const observer = new IntersectionObserver(onImageInView, {
            rootMargin: '100px',
        });

        $('picture.js-image-async, img[data-src]').each(function() {
            observer.observe(this);
        });

        function onImageInView(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {

                    if (entry.target.nodeName === "PICTURE") {
                        const sources = $(entry.target).find('source[data-src]');

                        $(sources).each(function(source) {
                            const src = $(this).data('src');

                            $(this).prop('srcset', src);
                        });
                    }
                    else if (entry.target.nodeName === "IMG") {
                        const el = $(entry.target);
                        const src = el.data('src');

                        if (!el.hasClass("owl-lazy")) {
                            el.prop('src', src)
                                .trigger('lazyloaded');
                        }
                    }

                    // stop observing
                    observer.unobserve(entry.target);
                }
            });
        }
    });
})(jQuery);
