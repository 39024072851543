const headerClassName = "button--active",
    contentClassName = "tab__content--active";

$(function() {
   $(".tab").on('click', '.js-tab__button', function() {
       const el = $(this).closest(".tab");
       const id = $(this).data("tab-id");

       $(el).find("." + headerClassName).removeClass(headerClassName);
       $(el).find("." + contentClassName).removeClass(contentClassName);

       $(this).addClass(headerClassName);
       $(el).find(".tab__content[data-tab-id='" + id + "']").addClass(contentClassName);
   });
});

