//DIT IS VOOR HET UITKLAPPEN VAN MEERDERE ITEMS

(function ($) {

	$(document).ready(function () {

		$('.collapse-js').click(function () {
			if ($(this).next().is(":visible")) {
				$(this).next().slideUp();
				$(this).closest('.accordeon__item').removeClass("accordeon__item--expanded")
			}
			else {
				$(this).next().slideDown();
				$(this).removeClass("accordeon__item--expanded");
				$(this).closest('.accordeon__item').addClass("accordeon__item--expanded");
			}
		});
	});

	// This function works also without adding and removing je expanded classes

})(jQuery);


//DIT IS VOOR HET UITKLAPPEN VAN 1 ITEM

(function($) {

    var allPanels = $('.accordion-content-js').hide();

    $('.accordion-js').click(function() {
        allPanels.each(function(index, value) {
            var panel = $(value);
            if(panel.closest('.accordeon__item').hasClass("accordeon__item--expanded")) {
                panel.closest('.accordeon__item').removeClass("accordeon__item--expanded");
                panel.slideUp();
            }
        });

        $(this).next().stop(true).slideDown();
        $(this).closest('.accordeon__item').toggleClass("accordeon__item--expanded");
        return false;
    });

})(jQuery);
