// In umbraco hoef je niets met de JS te doen.

function onDecline(event) {
	event.preventDefault();

	setCookie({
		fundamental: false,
		statistics: false,
		socialmedia: false,
		advertisements: false,
		interests: false
	});
}

function onAccept(event) {
	event.preventDefault();

	var cookie = {
		fundamental: true,
		statistics: true,
		socialmedia: true,
		advertisements: true,
		interests: true
	};

	document.querySelectorAll('.js-cookiebar input[type="checkbox"]').forEach(function (value, index) {
		cookie[value.name] = value.checked;
	});

	setCookie(cookie);
}

function setCookie(obj) {
	// create cookie expiration date
	const expiration = new Date();
	// set expire date (1 year)
	expiration.setDate(expiration.getDate() + 365);

	// create cookie
	var cookie = "CookieSettings=";
	cookie += "fundamental=" + (obj.fundamental !== undefined ? obj.fundamental : true) + "&";
	cookie += "statistics=" + (obj.statistics !== undefined ? obj.statistics : true) + "&";
	cookie += "socialmedia=" + (obj.socialmedia !== undefined ? obj.socialmedia : true) + "&";
	cookie += "advertisements=" + (obj.advertisements !== undefined ? obj.advertisements : true) + "&";
	cookie += "interests=" + (obj.interests !== undefined ? obj.interests : true) + ";";
	cookie += "expires=" + expiration + ";";

	// set cookie
	document.cookie = cookie;

	// remove all cookiebars from page
	document.querySelectorAll('.js-cookiebar')
		.forEach(function (value, index) {
			value.remove();
		});
}
